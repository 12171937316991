.experience {
  padding-top: 3rem;
 
}

.exp-flex {
  display: flex;
  gap: 1rem;
  background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
  height: 30px;
  border-radius: 11px;

}

.exp {
  margin-top: 20px;

}

.exp-index {
  color: black;
  font-weight: 500;
  background-color: #01be96;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exp-tenure {
  margin-top: 3px;
}

.exp-details {

  padding: 10px 0 0px 3%;
}
.exp-details h5{
font-size: 1.3rem;
}

.exp-line {
  border-left: 1px solid rgb(43, 43, 53);
  margin-left: 12px;
}

@media (max-width: 768px) {
  .experience {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .exp-details h5{
    font-size: 1rem;
    line-height: 1.5;
    }
    
}


