.slider-style-6 {
  /* background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%); */
  background-color: #191923;
  margin-top: 44px;
}

.slider-style-6 .appreciate_p {
  text-align: center;
  /* padding-right: 53px; */
}

/* @media (max-width: 991px) {
  .slider-style-6 .appreciate_p {
    padding-right: 0px;
    text-align: start;
    margin-top: 26px;
  }
} */

@media (max-width: 767px) {
  .slider-style-6 {
    overflow: hidden;
    padding: 0px 0;
    padding-top: 170px;
  }

  /* .slider-style-6.shape-right .shape-image,
  .slider-style-6.shape-left .shape-image {
    display: none;
  } */

  .slider-style-6.inheader-not-transparent {
    padding-top: 100px;
  }

  .slider-style-6 .appreciate_p {
    padding-right: 0px;
    text-align: start;
    margin-top: 26px;
  }
}

.slider-style-6.shape-right {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

/* .slider-style-6.shape-right .shape-image {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.5;
  width: auto;
} */

.slider-style-6.shape-left {
  position: relative;
}

/* .slider-style-6.shape-left .inner {
  position: relative;
  z-index: 2;
  margin-top: 30%;

} */

.inner {
  position: relative;
  z-index: 2;
  margin-top: 10%;
}

/* .slider-style-6.shape-left .shape-image {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
  transform: rotate(180deg);
  height: 100%;
  bottom: 0;
  width: auto;
} */

.slider-style-6 .inner .title {
  text-align: center;
  margin-bottom: 25px;
  display: block;
  font-size: 36px;
  line-height: 1.1;
}

@media (max-width: 1199px) {
  .slider-style-6 .inner .title {
    font-size: 30px;

  }

  .slider-style-6.shape-left .inner {

    margin-top: 0px;
  }
}

@media (max-width: 991px) {
  .slider-style-6 .inner .title {
    font-size: 25px;
  }

  .slider-style-6.shape-left .inner {

    margin-top: 90px;
  }
}

@media (max-width: 767px) {
  .slider-style-6 .inner .title {
    font-size: 25px;
    text-align: start;

  }

  .slider-style-6 .inner .title br {
    display: none;
  }

  .slider-style-6.shape-left .inner {
    margin-top: 0px;
  }
}

.slider-style-6 .inner .description {
  font-size: 22px;
  line-height: 43px;
}

@media (max-width: 991px) {
  .slider-style-6 .inner .description {
    font-size: 20px;
    line-height: 38px;
  }
}

@media (max-width: 767px) {
  .slider-style-6 .inner .description {
    font-size: 18px;
    line-height: 30px;
  }
}

.slider-style-6 .inner a.btn-default.btn-border {
  border-color: var(--color-border);
  color: var(--color-white);
}

.profileImage {
  /* align-items: center; */
  justify-content: center;
  display: flex;
  margin-top: 84px;

}

@media (max-width: 767px) {
  .profileImage {
    margin-top: 30px;
  }
}

.imgg {
  transition: transform 400ms ease-in-out;
  margin-top: -12%;
}

/* .imgg:hover {
  transform: translateY(-10px);
} */

.height-500 {
  height: 550px;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .height-500 {
    height: auto;
    padding-top: 0px;
  }
}

@media (max-width: 767px) {
  .height-500 {
    height: auto;
    padding: 0px 0;
  }
}

.vector1 {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 150%;
}

.vector1::before {
  content: '';
  position: absolute;
  left: 50%;
  top: -70%;
  bottom: 2.91%;
  width: 2px;
  height: 60%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.vector1::after {
  content: '';
  position: absolute;
  left: 50%;
  right: -25%;
  top: 110%;
  bottom: -97.09%;
  width: 2px;
  height: 60%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  transform: rotate(-180deg);

}

@media (max-width: 767px) {
  .vector1 {
    margin-top: 90%;
  }
  .vector1::before {
    top: -50%;
    width: 1.5px;
    height: 40%;
  }
  .vector1::after {
    content: '';
    top: 110%;
    width: 1.5px;
    height: 40%;  
  }
}


.Hero-tag {
  max-width: fit-content;
  padding-left: 2%;
  padding-right: 2%;
  position: absolute;
  height: 49px;
  left: 897px;
  right: 110px;
  top: 318px;
  background: #0DCB81;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: bold;
}


.hero-third-section{
  margin-left: 25%;
}
.part1{
  text-align: center;
}
.baseline{
  margin-top: 5%;
}

.part3{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .Hero-tag {
    left: 50%;
    transform: translateX(-50%);
    top: 250px;
    padding-left: 5%;
    padding-right: 5%;
    height: 40px;
    font-size: 12px;
  }

  .hero-third-section{
    margin-left: 5%;
  }
}
.Hero-downArrow{
  border: 1px solid white;
  border-radius: 50px;
  padding: 12% 5%;
  color:white;
  display: flex;
  justify-content: center;
  margin-right: 5%;
  background-color: transparent;
}
.scroll-down{
  width:25%;
}