.skill_title {
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 60px;
  color: white;
  text-shadow: 2px 2px 4px rgba(127, 255, 212, 0.4);

  @media (max-width: 768px) {
    margin-top: 22px;
    font-size: 32px;
  }
}

.scroll-bar::-webkit-scrollbar {
  width: 7px;

}

.scroll-bar::-webkit-scrollbar-track {
  background: black;

}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #312e2e;
}