/*  after-vision */
/* .banner-two {
    margin-top: 150px;
} */

.after-vision {
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;

    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@media (max-width: 768px) {
    .banner-two {
        margin-top: 50px;
    } 
     .after-vision {

        margin-top: 30px;
        margin-bottom: 30px;


    }
}

.section-subheading {
    color: #0DB474
}