/* .certifications{
    padding: 8rem 0;
}

.certificates img{
   
   width:556px;
   height:406px;
   padding: 10px 0;
  border-radius: 30px;
} */
.background-image_certificates{
    /* padding: 8rem 0; */
    height: 200px !important;
    width: auto;
    background-size: cover;
    background-position: center;
}
.certifications {
    padding-top: 30rem ;
}

.certificates {
    text-align: center;
}

.certificate-img {
    max-width: 100%; 
    width:556px;
    height:406px;
    max-width: 100%; 
    border-radius: 25px;
    padding: 15px 0;
}

@media (max-width: 768px) {
   
    .certificate-img{
        width: 93%; 
        height: auto;
        
    }
    .certifications {
        padding: 4.5rem 0;
        overflow-x: hidden;
    }
}


.section-title {
    color: #0DCB81
}

/* styles.css */
.carousel-container {
    width: 100%;
    margin: auto;
  }
  
  .carousel-item {
    text-align: center;
    padding: 0% 5%;
    position: relative;
  }
  
  .carousel-item img {
    width: 100%;
    height: auto;
  }
  
  .slick-arrow {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    padding: 10px;
    border-radius: 50%;
    z-index: 5;
  }
  
  .slick-prev {
    left: -25px;
  }
  
  .slick-next {
    right: 0px;
  }
  