.pre-title {
    font-size: small;
    color: #0DCB81
}

.circle-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-content {
    width: 203px;
    height: 203px;
    background-color: #191923;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}