/* .awards{
    padding: 8rem 0;
}

.award img{

   width:486px;
   height:436px;
   padding: 10px 0;
  border-radius: 30px;
} */

.awards {
    padding-top: 8rem ;
}

.award {
    text-align: center;
}

.award-img {
    max-width: 100%; /* Ensures the image is responsive */
    height: auto;
    width: 486px; /* Set initial width */
    max-width: 100%; /* Ensures the image is responsive */
    border-radius: 25px;
    padding: 10px 0;
}

@media (max-width: 768px) {
   
    .award-img {
        width: 95%; 
        /* margin-left: 2%; */
    }
    .awards {
        padding: 4.5rem 0;
        overflow-x: hidden;
    }
}







/* testimonials */
.testimonials{
    padding: 4rem 0;
}
@media (max-width: 768px) {
   
    .testimonials{
        padding: 3rem 0;
    }
}