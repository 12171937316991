
.skill_bg{
    /* background-color: black; */
    /* box-shadow: 0px 0px 5px rgba(127, 255, 212, 0.4); */

      background-color: #0e0e10;


}
.skill_shadow_bottom{
margin-top: 60px;
  width: 100%; 
  height: 90px; 
  background: linear-gradient(0deg, rgba(127, 255, 212, 17%) 0%, rgba(23, 26, 29, 0) 100%) !important

}
.skill_shadow_top{
    width: 100%; 
    height: 120px; 
    background: linear-gradient(to bottom, rgba(127, 255, 212, 0.10) 0%, rgba(23, 26, 29, 0) 100%) !important;
  }

  
  .skill_title {
    font-size: 42px;
    text-align: center;
    font-weight: 600;
    margin-top: 60px;
    color: white;
    text-shadow: 2px 2px 4px rgba(127, 255, 212, 0.4);
    @media (max-width: 768px) {
      margin-top: 22px;
      font-size: 32px;
    }
  }
  
  .skill_desc {
    font-size: 16px;
    letter-spacing: 0.3px;
   margin-top: 10px;
    text-align: center;
  
    color: white;
  
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  

  
  .skill {
    margin-top: 40px;
    background: #191923;
    /* background: linear-gradient(159deg,rgb(45,45,58) 0%,rgb(43,43,53) 100%); */
    border: 1px solid rgba(127, 255, 212, 0.20); 
box-shadow: rgba(127, 255, 212, 0.15) 0px 0px 10px;
    border-radius: 16px;
    padding: 18px 20px;
  
    @media (max-width: 768px) {
      padding: 10px 36px;
    }
  
    @media (max-width: 500px) {
      padding: 10px 36px;
    }
  }
  
  .skill-title {
    font-size: 28px;
    font-weight: 600;
    color: white;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .skill-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;
  }
  
  .skill-item {
    font-size: 16px;
    /* font-weight: 400; */
    color: white;
    background-color: #191923;

    /* border: 1px solid #CCCCCC;   */
     border: 1px solid rgba(127, 255, 212, 0.20); 
    border-radius: 12px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  
    @media (max-width: 768px) {
      font-size: 14px;
      padding: 8px 12px;
    }
  
    @media (max-width: 500px) {
      font-size: 14px;
      padding: 6px 12px;
    }
  }
  
  .skill-image {
    width: 24px;
    height: 24px;
  } 