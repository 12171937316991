/* .footer-alt{
    border-top: 1px solid rgba(255,255,255,0.10);
    padding: 10px 0px;
    color: rgba(255,255,255,0.4);
    text-align: center;
} */
.footerOne {
    padding: 2rem 0;
}


/* .icons {
    display: flex;
    gap: 2rem;
    margin-top: 10px;
    justify-content: center;
    align-items: center;

}


.icons span {
    margin: 0 10px;   
}

.icons i {

    color: #01be96;
    font-size: 22px;
} */
.profiles {
    text-align: center;
  }
  
  .icons {
    display: flex;
    gap: 2rem;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }
  
  .icons span {
    position: relative;
  }
  
.icons i {
    color:white;
    font-size: 22px;
    border: 1px solid #01be96; 
    border-radius: 50%; 
    padding-top: 8px; 
    width: 40px;
    height: 40px;
  }
  
  .icons i:hover {
    color:#01be96;
   
  }

  .links {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    text-decoration: none;
}

.footer-phone,
.footer-mail {
    display: flex;
    gap: 0.5rem;
    margin-top: 5px;
}

.footer-phone i,
.footer-mail i {
    color: #01be96;
    font-size: 22px;
}

.footer-phone a,
.footer-mail a {
    text-decoration: none;
    color: white;
    transition: all 400ms ease;
}

.footer-phone a:hover,
.footer-mail a:hover {

    color: #01be96;
}

@media screen and (min-width: 769px) {
    .links {
      flex-direction: row; 
     
    }
  
    .footer-phone,
    .footer-mail {
      margin-top: 0; 
   
    }
  }

  @media screen and (max-width: 769px) {
 .footer-phone,
    .footer-mail {
  justify-content: center;
    }
.footer-head{
    text-align: center;
}
.footer-head2{
    text-align: center;
    margin-top: 12px;
}


  }


.footer-alt {
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    padding: 10px 0;
}

.last-footer {
    margin: 0;

}

.last-footer a {
    /* margin: 0;  */
    /* color: rgb(189, 11, 11); */
    color: #a11818;
    text-decoration: none;
    font-weight: 600;

}




.top-heading{
  width:45%;
  margin:0% auto;
}
.footer-contact-me-btn{
  width:150px;
  height: 150px;
  background-color: #10B676;
  border: 1px solid #10B676;
  border-radius: 50%;
  margin:0% auto;
}
.container-right{
  background-color: #171B22;
}
.subtitle {
  font-size: medium;
  color: #828385;
  text-align: justify;
}
.fontsiz{
  font-size: 1.1em;
}
.footer-heading{
  color:#10B676;
}
.footer-link{
  text-decoration: none;
  color:white;
}
