.slick-slider,
.slick-list {
    padding: 0;
}

 /* .slick-track {
    padding-top: 1.5rem;
    padding-bottom: 0;

}  */

/* Slick Dots */
.rn-slick-dot .slick-dots {
    bottom: 30px;
}

@media (max-width: 768px) {
    .rn-slick-dot .slick-dots {
        bottom: 7%;
    }

}

.rn-slick-dot .slick-dots li {
    width: 30px;
    margin: 0;
    opacity: 1;
    position: relative;
}

.rn-slick-dot .slick-dots li button::before {
    display: none;
}

.rn-slick-dot .slick-dots li button::after {

    width: 100%;
    display: block;
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-left: 11px;
    background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
    /* box-shadow: inset 0 0 0 5px var(--color-lessdark); */
    transition: all 0.3s ease;
}

.rn-slick-dot .slick-dots li.slick-active {
    opacity: 1;
}

.rn-slick-dot .slick-dots li.slick-active button::after {
    transform: scale(2);
    box-shadow: inset 0 0 0 1px var(--color-primary);
}

/* Dot Light */
.rn-slick-dot.dot-light .slick-dots li button::after {
    background: #ffffff;
    box-shadow: inset 0 0 0 5px #ffffff;
}

.rn-slick-dot.dot-light .slick-dots li.slick-active button::after {
    transform: scale(2);
    box-shadow: inset 0 0 0 1px var(--color-primary);
}

/* Slick Arrow */
.rn-slick-arrow button.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    transition: 0.5s;
    opacity: 0;
    width: 48px;
    height: 48px;
    border: 2px solid rgb(43, 43, 53);
    border-radius: 100%;
    color: var(--color-border);
}

.rn-slick-arrow button.slick-arrow:hover {
    background: #01be96;
    border: 2px solid #107d65;
}

.rn-slick-arrow button.slick-arrow::before {
    line-height: 1;
    opacity: 0.4;
    position: relative;
    top: -2px;
    transition: 0.5s;
    z-index: 2;
}

.rn-slick-arrow button.slick-prev {
    left: 15px;
}

.rn-slick-arrow button.slick-prev::before {
    content: url(../images/slick-arrow-left-small.webp);
}

.rn-slick-arrow button.slick-prev:hover {
    left: 10px;

}

/* .rn-slick-arrow button.slick-prev::before {
        opacity: 1;
    } */
.rn-slick-arrow button.slick-next {
    right: 15px;

}

.rn-slick-arrow button.slick-next::before {
    content: url(../images/slick-arrow-right-small.webp);

}

.rn-slick-arrow button.slick-next:hover {
    right: 10px;
    /* Adjusted to match the left button */

}

/* .rn-slick-arrow button.slick-next::before {
        opacity: 1;
    } */
.rn-slick-arrow:hover button.slick-arrow {
    opacity: 1;
}

@media (max-width: 768px) {
    .rn-slick-arrow button.slick-arrow {
        display: none !important;
    }
}

/* Slider Style 4 */
.slider-style-4 .inner {
    padding-top: 70px ;
}

@media (max-width: 768px) {
    .slider-style-4 .inner {
        padding: 50px 0;
    }
}

.slider-style-4 .inner>* {
    animation-name: fadeOutUp;
    animation-fill-mode: both;
    animation-duration: 1s;
}

.slider-style-4 .inner .title {
    font-family: var(--font-primary);
    margin-bottom: 25px;
    display: block;
    font-size: 40px;
    line-height: 1.1;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: var(--color-white);
}

@media (max-width: 768px) {
    .slider-style-4 .inner .title {
        font-size: 25px;
    }
}

.slider-style-4 .inner .description {
    font-size: 20px;
    line-height: 43px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: var(--color-body);
}

@media (max-width: 768px) {
    .slider-style-4 .inner .description {
        font-size: 20px;
        line-height: 35px;
    }
}

.slider-style-4 .inner .button-group {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.slider-style-4.variation-2 .inner .title {
    font-size: 70px;
}

@media (max-width: 768px) {
    .slider-style-4.variation-2 .inner .title {
        font-size: 42px;
    }
}

.slider-style-4.variation-2 .bg-overlay::before,
.slider-style-4.variation-3 .bg-overlay::before {
    background: #000000;
    content: '';
    opacity: 0.5;
    background-image: none;
}

/* Height 750 */
.height-750 {
    height: 410px;
    display: flex;
    align-items: center;
}

@media (max-width: 992px) {
    .height-750 {
        height: auto;
        padding: 100px 0;
    }
}

@media (max-width: 768px) {

  
    .height-750 {
        height:400px;

        padding: 50px 0;
    }
}

/* Slick Dots */
/* .slider-dot.rn-slick-dot .slick-dots {
    bottom: 30px;
} */

/* Slider Area */
.slider-area .inner a.btn-default.btn-border {
    border-color: yellow;
    color: orangered;
}

/* Slider Style 4 Active Animation */
.slider-style-4 .slick-active .inner>* {
    animation-name: fadeInUp;
}

.slider-style-4 .slick-active .inner .title {
    animation-delay: 0.5s;
}

.slider-style-4 .slick-active .inner .description {
    animation-delay: 1s;
}

.slider-style-4 .slick-active .inner .button-group {
    animation-delay: 1.2s;
}