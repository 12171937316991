/* navbar */
.navbar {
    /* background-color: #212529; */
    background-color: #0e0e10;
    border-bottom: 0.1rem solid #191923;
}

.navbar-logo {
    width: 66px;
    height: 66px;
    margin-left: 75px;
}

.navbar-btn {
    font-size: 14px;
    border: 1px solid black;
    border-radius: 6px;
    font-weight: 600;
    margin-left: auto;
    margin-right: 5%;
    transition: 4ms all;
    padding: 7px;
}

.navbar-btn:hover {
    /* color: aquamarine;
    background: black;
    border: 1px solid aquamarine; */
    box-shadow: 5px 5px 5px rgba(127, 255, 212, 0.4);
}

.color_nav_li {
    color: white;
    transition: color 0.3s ease;
}

.color_nav_li:hover {
    color: #01be96  ;
}


.navbar-toggler-icon {
    background-color: #01be96  ;
    border-radius: 4px;
}

li a:focus {
    color: #01be96  !important
}

.navbar-brand {
    color: #01be96  !important;
}

@media screen and (min-width:320px) and (max-width: 768px) {

    .navbar-border {
        border-bottom: 1px solid #1a1a1a;

    }

    .navbar-logo {
        margin-left: 20px;
        width: 50px;
        height: 50px;
    }

    .navbar-list li {
        margin-left: 20px;
        padding-right: 50px;
    }

    .navbar-btn {
        font-size: 14px;
        border: 1px solid black;
        border-radius: 6px;
        font-weight: 600;
        margin-left: 19px;
        width: 100px;
        margin-top: 6px;
    }
}

@media screen and (min-width:1000px) and (min-width: 1024px) {
    .navbar-list {
        margin-left: 2%;
    }

    .navbar-list li {
        margin-left: 20px;
    }

    .navbar-brand {
        margin-left: 80px;
    }
    .navbar-logo {
        margin-left: 20px;
   
    }

}

@media screen and (min-width: 1025px) {
    .navbar-list {
        margin-left: 20%;
    }

    .navbar-list li {
        margin-left: 20px;
    }
    .navbar-logo {
        margin-left: 60px;
   
    }
    .navbar-btn {
       
        margin-left: 109px;
        
    }
}