.my_container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #0e0e10;
}

.my_circle {
    width: 7px;
    height: 10px;
    background-color: #0DCB81;
    border-radius: 30px;
}

.my_line {
    margin: 0.5rem 0;
    width: 2px;
    height: 100%;
    background-color: white;
}

.tenure,
.designation,
.company,
.details {
    color: white;
}

.center_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parent_div {
    display: flex;
}

.left_div {
    display: flex !important;
    align-items: end !important;
    flex-direction: column !important;
}

.my_btn {
    background-color: transparent !important;
    border: 1px solid #01be96 !important;
    color: #01be96 !important;
    padding: 0.5rem 1.3rem !important;
    font-size: 1.1rem !important;
}

.my_btn:hover {
    background-color: #01be96 !important;
    color: white !important;
}

.btn_row {
    padding: 2rem 0;
}

.designation {
    text-align: end;
}

@media only screen and (max-width: 768px) {
    .div__parent {
        padding: 1rem 0 !important;
    }

    .tenure,
    .designation {
        text-align: center;

    }

    .left_div {

        align-items: center !important;

    }

}

@media only screen and (max-width: 768px) {
    .experience_parent {
        display: flex;
        flex-direction: column !important;
    }

    .tenure,
    .designation {
        font-size: 17px;
        text-align: center;
        /* display: flex; */
        width: 100%;
        justify-content: center;
    }

    .company {
        font-size: 16px;
        border-bottom: 1px solid #01be96;
        padding-bottom: 10px;
    }

    .left_div,
    .right_div {
        text-align: center;
        align-items: center;
        padding: 0.5rem 0;
    }

    .center_div {
        display: none;
    }
}


.section-subheading {
    color: #0DB474
}

.subtitle {
    font-size: small;
    color: #828385;
}

.lollipop-container {
    position: relative;
}

.lollipop-boundary {
    border: 1px solid white;
    position: absolute;
    left: 10px;
    top: 7px;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lollipop-upper {

    width: 10px;
    height: 10px;
    background: #0DCB81;
    border-radius: 10px;
}
.lollipop-stick{
    background-color: white;
    width: 1.5px;
    height:30px;
    position: absolute;
    left: 18px;
    top: 25px;
}
.time-frame{
    font-size: smaller;
    color: #828385;
    margin: 5% 0%;
}
.experience-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.experience-img-back{
    position: absolute;
    top:25.7%;
    z-index: -1;
}

@media screen and (max-width: 768px) {
    .mentor-data {
        margin-left: 10%;
        font-size: small;
        width:85%;
    }
    
    .experience-img-back{
        top:13.2%;
        width:90%;
    }

}