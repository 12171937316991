

.personal_description{
    text-align: justify;
    /* width: 90%; */
    font-size: 1.275em;
}

.about-video{
    width: 550px;
    height: 350px;
    margin-top: 10px;
    border-radius: 50px;
}


.video__div{
    /* display: flex; */
    justify-content: end;
}

@media only screen and (max-width: 768px){
    .video__div{
        display: flex;
        justify-content: center;
    }    
    .mission-head{
        margin-top: 30px;
    }   
     .about-video{
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 426px){
    .about-video{
        width: 100%;
        height: 180px;
    }
}

@media only screen and (min-width:769px ) and (max-width: 1027px){
    .about-video{
        width: 100%;
        height: 300px;
    }
}
.about-section-border{
    width:90%;
    border-top-right-radius: 230px;
    border-bottom-right-radius: 230px;
    border: 1px solid #0C9C69;
}