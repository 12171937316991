.rn-back-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height: 46px;
    border-radius: 50%;
    text-align: center;
    z-index: 999 !important;
    background: linear-gradient(159deg,rgb(111,45,58) 1%,rgb(43,43,53) 100%);
    svg {
        color: #fff;
        width: 27px;
        height: 27px;
        font-size: 14px !important;
    }
}
.rn-back-top1 {
    position: fixed;
    bottom: 90px;
    right: 30px;
    cursor: pointer !important;
    z-index: 999;
    width: fit-content;
    padding: 0.5rem 0.6rem ;
    border-radius: 50%;
    text-align: center;
    z-index: 999 !important;
    background-color: #4dca5a;
    svg {
        color: #fff;
        width: 30px;
        height: 30px;
        font-size: 20px !important;
    }
}