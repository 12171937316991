/* contact */
.contact_bg {
  /* background-color: black; */
  /* box-shadow: 0px 0px 5px rgba(127, 255, 212, 0.4); */
  margin-top: 80px;
  padding-bottom: 50px;

}

.contact_heading {
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 60px;
  color: white;
  text-shadow: 2px 2px 4px rgba(127, 255, 212, 0.4);

  @media (max-width: 768px) {
    margin-top: 22px;
    font-size: 32px;
  }
}

.form-group input {
  height: 50px;
  padding: 0 20px;
  text-decoration: none;
  border-radius: 12px;
  margin-top: 17px;
  width: 450px;

  border: 1px solid rgba(127, 255, 212, 0.20);
  /* background: linear-gradient(159deg,rgb(45,45,58) 0%,rgb(43,43,53) 100%); */
  background-color: #191923;
  color: white;
}

.form-group textarea {
  height: 180px;
  padding-top: 20px;
  padding-left: 20px;
  text-decoration: none;
  border-radius: 12px;
  margin-top: 20px;
  width: 450px;
  border: 0.1px solid rgba(128, 128, 128, 0.40);
  /* background: linear-gradient(159deg,rgb(45,45,58) 0%,rgb(43,43,53) 100%); */
  background-color: #191923;
  color: white;
}

input:focus,
textarea:focus {
  border: 1px solid rgba(128, 128, 128, 0.40);
  box-shadow: rgba(128, 128, 128, 0.40);
  outline: none;
}

@media (max-width: 768px) {

  .form-group input,
  .form-group textarea {
    width: 100%;
  }
}

.btn-send {
  border: 1px solid aquamarine;
  width: 30%;
  height: 50px;
  /* margin-top: 20px; */
  border-radius: 10px;
  background-color: white;
  color: rgb(97, 94, 94);
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-send:hover {
  /* background:linear-gradient(159deg,rgb(45,45,58) 0%,rgb(43,43,53) 100%); */
  /* color: white; */
  box-shadow: 5px 5px 5px rgba(127, 255, 212, 0.4);
}




/* math captcha */
.captcha {
  margin-top: 10px;
}

.math-captcha {
  display: flex;
  gap: 1rem;
}

.math-captcha p {
  margin-top: 15px;
}

.math-captcha input {
  height: 50px;
  padding: 0 20px;
  text-decoration: none;
  border-radius: 12px;
  /* margin-top: 17px; */
  width: 70px;

  border: 1px solid rgba(127, 255, 212, 0.20);
  /* background: linear-gradient(159deg,rgb(45,45,58) 0%,rgb(43,43,53) 100%); */
  background-color: #191923;
  color: white;
}


.section-title {
  width: 40%;
  color: #0DCB81
}

.section-title2 {
  width: 54.8%;
  color: #0DCB81
}

.section-title2-span {
  color: #E6EBF4
}

.no-border-bottom {
  border: none;
  border-bottom: 2px solid #969BB2;
  border-radius: 0;
  background-color: transparent;
}

.no-border-bottom::placeholder {
  color: #969BB2;
}

.no-border-bottom:focus {
  box-shadow: none;
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #86B7FE;
  color: #969BB2;
}

.submit-btn {
  background-color: #18D48C;
  margin-bottom: 5%;
}

.contact-section-below-grid-container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  cursor: pointer;

}

.contact-section-below-grid-btn {
  background-color: #E6EBF4;
  border: none;
  border-radius: 25px;
  width: 80%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
}
@media screen and (max-width: 768px) {
  .section-title {
    width: 70%;
    margin-bottom: 5%;
  }
  .contact-section-below-grid-container {
    margin-bottom: 5%;
  }
}
.contact-img{
  border-radius: 30px;
}