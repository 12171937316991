.subtitle {
    font-size: medium;
    color: #828385;
}

.ReadMore-btn {
    background-color: #232327;
    border: 1px solid #10B676;
    color: white;
    border-radius: 40px;
    padding: 3% 5%;
}

.service-circle-svg-container {
    position: relative;
}

.service-circle-svg {
    position: absolute;
    top: -150px;
    right: -5%;
    z-index: -1;
}

.accordian-container {
    border: 1px solid #828385;
    border-radius: 40px;
}

.question {
    color: #10B676;
}

.font-large {
    font-size: 9em;
}

.Why-choose-about-btn-container {
    position: relative;
    margin-left: 70%;
}

.Why-choose-about-btn {
    width: 150px;
    height: 150px;
    background-color: #10B676;
    border: 1px solid #10B676;
    border-radius: 50%;
}

.about-btn-line-one,
.about-btn-line-two,
.about-btn-line-three {
    /* width:50px; */
    height: 2px;
    background-color: #828385;
    position: absolute;
}

.about-btn-line-one {
    width: 30px;
    right: 4%;
    top: 0%;
    transform: rotate(-50deg);
}

.about-btn-line-two {
    width: 45px;
    right: -1%;
    top: 15%;
    transform: rotate(-20deg);
}

.about-btn-line-three {
    width: 20px;
    right: 2%;
    top: 35%;
    transform: rotate(10deg);
}

@media screen and (max-width: 768px) {
    .Why-choose-about-btn-container {
        margin-left: 50%;
    }

    .about-btn-line-one {
        right: 14%;
    }

    .about-btn-line-three {
        right: 10%;
    }
}

.accordion-button:not(.collapsed) {
    color: white !important;
    box-shadow: none !important;
}
.accordion-item {
    border: none !important;
}
.css-f7wxzj-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: white !important;
}